import React, { Fragment, useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { request } from "graphql-request";
import Blog from "./components/Blog/Blog";
import Post from "./components/Blog/Post/Post";
import Error404 from "./components/Error/Error404";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

function App() {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      const { posts } = await request(
        "https://api-us-west-2.graphcms.com/v2/ckg79ljmwdm0201xhe3cpg3h9/master",
        `
        { 
          posts(orderBy: date_DESC) {
            id
            title
            slug
            date
            excerpt
            tags
            author {
              name
            }
            content {
              html
            }
            coverImage {
              url
            }
          }
        }
      `
      );

      setPosts(posts);
    };

    fetchPosts();
  }, []);

  return !posts ? (
    <h2>Loading...</h2>
  ) : (
    <Fragment>
      <Header title="Written In Squid Ink" />
      <main>
        <Switch>
          <Route exact path="/">
            <Blog posts={posts} />
          </Route>
          <Route path="/post/:slug">
            <Post posts={posts} />
          </Route>
          <Route component={Error404} />
        </Switch>
      </main>
      <Footer />
    </Fragment>
  );
}

export default withRouter(App);
