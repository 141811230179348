import React from "react";

const Header = React.memo(({ title }) => (
  <header>
    <h1>
      <a href="/">{title}</a>
    </h1>
  </header>
));

export default Header;
