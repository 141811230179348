import React from "react";

const Footer = React.memo((props) => (
  <footer>
    <ul>
      <li>&copy;{new Date().getFullYear()} Jon Varner</li>
    </ul>
    <ul>
      <li>
        Music:{" "}
        <a href="https://squidinsquidink.bandcamp.com/">
          squidinsquidink.bandcamp.com
        </a>
      </li>
      <li>
        Slack:{" "}
        <a href="https://fortunatelygone.slack.com/">
          fortunatelygone.slack.com
        </a>
      </li>
    </ul>
  </footer>
));

export default Footer;
