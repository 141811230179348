import React from "react";
import { Link } from "react-router-dom";

const Blog = ({ posts }) => {
  return (
    <div className="blog">
      {posts.map((post) => (
        <article className="blog-content" key={post.id}>
          {post.coverImage && (
            <img src={post.coverImage.url} alt={post.title} />
          )}
          <h2>
            <Link to={`/post/${post.slug}`}>{post.title}</Link>
          </h2>
          <h3>{post.date}</h3>
          <p>{post.excerpt}</p>
          <Link to={`/post/${post.slug}`}>Read more...</Link>
        </article>
      ))}
    </div>
  );
};

export default Blog;
