import React from "react";
import { useParams } from "react-router-dom";

const Post = ({ posts }) => {
  const { slug } = useParams();
  const post = posts.find((post) => post.slug === slug);

  return (
    <article className="blog-post">
      {post.coverImage && <img src={post.coverImage.url} alt={post.title} />}
      <h2>{post.title}</h2>
      <h3>{post.date}</h3>
      <div dangerouslySetInnerHTML={{ __html: post.content.html }} />
    </article>
  );
};

export default Post;
